// Global Imports
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import GoogleTranslate from "./components/GoogleTranslate";
import JosefinSans from "./pages/about/JosefinSans";

// Import custom CSS
import "../src/assets/global.css";

// Main Page Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Navigation Components
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Schools = lazy(() => import("./pages/Schools"));
const Departments = lazy(() => import("./pages/Departments"));
const Programs = lazy(() => import("./pages/Programs"));
const Media = lazy(() => import("./pages/Media"));
const Employment = lazy(() => import("./pages/Employment"));

// Footer Navigation Components
const MapsDirections = lazy(() => import("./pages/about/MapsDirections"));
const StaffQuickLinks = lazy(() => import("./pages/about/StaffQuickLinks"));
const Nondiscrimination = lazy(() => import("./pages/about/Nondiscrimination"));
const StaffDirectory = lazy(() => import("./pages/about/StaffDirectory"));
const SiteMap = lazy(() => import("./pages/about/SiteMap"));
const AIInformation = lazy(() => import("./pages/about/AIInformation"));
const Calendar = lazy(() => import("./pages/about/Calendar"));
const Schoolboard = lazy(() => import("./pages/about/Schoolboard"));
const Superintendent = lazy(() => import("./pages/about/Superintendent"));
const Sponsors = lazy(() => import("./pages/about/Sponsors"));

// Department Page Components
const Admin = lazy(() => import("./pages/departments/Admin"));
const Athletics = lazy(() => import("./pages/departments/Athletics"));
const CapitalProjects = lazy(() =>
  import("./pages/departments/CapitalProjects")
);
const FederalPrograms = lazy(() =>
  import("./pages/departments/FederalPrograms")
);
const Finance = lazy(() => import("./pages/departments/Finance"));
const Hr = lazy(() => import("./pages/departments/Hr"));
const Nutrition = lazy(() => import("./pages/departments/Nutrition"));
const Operations = lazy(() => import("./pages/departments/Operations"));
const Purchasing = lazy(() => import("./pages/departments/Purchasing"));
const Sped = lazy(() => import("./pages/departments/Sped"));
const Learning = lazy(() => import("./pages/departments/Learning"));
const Technology = lazy(() => import("./pages/departments/Technology"));

// Programs Components
const OpportunityCulture = lazy(() =>
  import("./pages/programs/OpportunityCulture")
);
const KidsReadNow = lazy(() => import("./pages/programs/KidsReadNow"));
const CrisisResponse = lazy(() => import("./pages/programs/CrisisResponse"));

// District Links on About Page
const BullyPolicy = lazy(() => import("./pages/about/BullyPolicy"));
const LunchMenu = lazy(() => import("./pages/districtmain/LunchMenu"));
const IPRARequest = lazy(() => import("./pages/about/IPRARequest"));
const TitleIX = lazy(() => import("./pages/about/TitleIX"));
const Message = lazy(() => import("./pages/about/Message"));

// District Button Group Components
const DirectoryD = lazy(() => import("./pages/districtmain/DirectoryD"));
const ParentsStudents = lazy(() =>
  import("./pages/districtmain/ParentsStudents")
);
const Antiracism = lazy(() => import("./pages/districtmain/Antiracism"));
const AnonAlerts = lazy(() => import("./pages/districtmain/AnonAlerts"));

// Other Pages
const Attendance = lazy(() => import("./pages/districtmain/Attendance"));
const TechProjects = lazy(() => import("./pages/departments/TechProjects"));

// CMSD Theaters
const CMSDTheaters = lazy(() => import("./pages/CMSDTheaters"));
const VenueRental = lazy(() => import("./pages/cmsdtheaters/VenueRental"));
const PatAbout = lazy(() => import("./pages/cmsdtheaters/About"));

// School Imports
const schoolImports = {
  CISAltaVista: ["AvHome", "AvAbout", "AvDirectory", "AvBellSchedule"],
  CISPRLeyva: ["PrHome", "PrAbout", "PrDirectory", "PrBellSchedule"],
  CHS: ["ChsHome", "ChsAbout", "ChsDirectory", "ChsBellSchedule"],
  CECHS: ["CechsHome", "CechsAbout", "CechsDirectory", "CechsBellSchedule"],
  CEC: ["CecHome", "CecAbout", "CecDirectory", "CecBellSchedule"],
  CW: ["CwHome", "CwAbout", "CwDirectory", "CwBellSchedule"],
  DW: ["DwHome", "DwAbout", "DwDirectory", "DwBellSchedule"],
  Mont: ["MontHome", "MontAbout", "MontDirectory", "MontBellSchedule"],
  Oco: ["OcoHome", "OcoAbout", "OcoDirectory", "OcoBellSchedule"],
  Riv: ["RivHome", "RivAbout", "RivDirectory", "RivBellSchedule"],
  Sun: ["SunHome", "SunAbout", "SunDirectory", "SunBellSchedule"],
  ECEC: ["ECECHome", "ECECAbout", "ECECDirectory", "ECECBellSchedule"],
};

const generateSchoolRoutes = (schoolName, routes) =>
  routes.map((route) => (
    <Route
      key={`${schoolName}-${route}`}
      path={`/schools/${schoolName.toLowerCase()}/${route.toLowerCase()}`}
      element={React.createElement(
        lazy(() => import(`./pages/schools/${schoolName}/${route}`))
      )}
    />
  ));

export default function App() {
  return (
    <>
      <Header />
      <GoogleTranslate />
      <div className="container">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/about/josefinsans" element={<JosefinSans />} />
            {/* Navigation Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/schools" element={<Schools />} />
            <Route path="/departments" element={<Departments />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/media" element={<Media />} />
            <Route path="/employment" element={<Employment />} />

            {/* Generate School Routes */}
            {Object.entries(schoolImports).flatMap(([schoolName, routes]) =>
              generateSchoolRoutes(schoolName, routes)
            )}

            {/* Department Routes */}
            <Route path="/departments/admin" element={<Admin />} />
            <Route path="/departments/athletics" element={<Athletics />} />
            <Route path="/departments/purchasing" element={<Purchasing />} />
            <Route path="/departments/hr" element={<Hr />} />
            <Route path="/departments/technology" element={<Technology />} />

            <Route path="/departments/operations" element={<Operations />} />
            <Route path="/departments/nutrition" element={<Nutrition />} />
            <Route path="/departments/learning" element={<Learning />} />
            <Route path="/departments/sped" element={<Sped />} />
            <Route
              path="/departments/federalprograms"
              element={<FederalPrograms />}
            />
            <Route path="/departments/finance" element={<Finance />} />
            <Route
              path="/departments/capitalprojects"
              element={<CapitalProjects />}
            />

            {/* District Links on About Page */}
            <Route path="/about/calendar" element={<Calendar />} />
            <Route path="/about/schoolboard" element={<Schoolboard />} />
            <Route path="/about/superintendent" element={<Superintendent />} />
            <Route path="/about/sponsors" element={<Sponsors />} />
            <Route path="/districtmain/lunchmenu" element={<LunchMenu />} />
            <Route
              path="/programs/opportunityculture"
              element={<OpportunityCulture />}
            />
            <Route path="/programs/KidsReadNow" element={<KidsReadNow />} />
            <Route
              path="/programs/crisisresponse"
              element={<CrisisResponse />}
            />
            <Route
              path="/about/parentsstudents"
              element={<ParentsStudents />}
            />
            <Route path="/districtmain/iprarequest" element={<IPRARequest />} />
            <Route path="/districtmain/titleix" element={<TitleIX />} />
            <Route path="/districtmain/message" element={<Message />} />
            <Route
              path="/about/staffquicklinks"
              element={<StaffQuickLinks />}
            />
            <Route
              path="/about/nondiscrimination"
              element={<Nondiscrimination />}
            />
            <Route path="/about/staffdirectory" element={<StaffDirectory />} />
            <Route path="/about/sitemap" element={<SiteMap />} />
            <Route path="/districtmain/bullypolicy" element={<BullyPolicy />} />
            <Route path="/about/aiinformation" element={<AIInformation />} />
            <Route path="/about/maps-directions" element={<MapsDirections />} />

            {/* District Button Group Routes */}
            <Route path="/districtmain/directoryd" element={<DirectoryD />} />
            <Route path="/districtmain/antiracism" element={<Antiracism />} />
            <Route path="/districtmain/anonalerts" element={<AnonAlerts />} />

            {/* Other Pages */}
            <Route path="/districtmain/attendance" element={<Attendance />} />
            <Route
              path="/departments/techprojects"
              element={<TechProjects />}
            />

            {/* CMSD Theaters */}
            <Route path="/cmsdtheaters" element={<CMSDTheaters />} />
            <Route path="/cmsdtheaters/VenueRental" element={<VenueRental />} />
            <Route path="/cmsdtheaters/About" element={<PatAbout />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}
